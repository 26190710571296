<template>
  <v-app-bar class="appbar">
    <v-toolbar-title>Friend Venn</v-toolbar-title>
  </v-app-bar>
</template>

<script>

export default {
  name: 'TitleHeader',


}
</script>

<style scoped>
.v-app-bar {
  background-color: #264653;
}

.v-toolbar-title {
  color: #fdf0d5;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
}
</style>