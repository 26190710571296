// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAQKQZIENjzVlGPti39QLUffb0DNWoXGgk",
  authDomain: "remember-when-534d3.firebaseapp.com",
  projectId: "remember-when-534d3",
  storageBucket: "remember-when-534d3.appspot.com",
  messagingSenderId: "1082207131799",
  appId: "1:1082207131799:web:5a966892522dee20a131d2",
  measurementId: "G-FMVWXYT76L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore();


export {app, analytics, firebaseConfig, db};